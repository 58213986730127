@import "../common";

.page-not-found {
    @include respond-to-min($desktopSize) {
        padding: 20vh 0;
        padding-top: 16vh;
    }
    @include respond-to-max($desktopSize) {
        padding: 20vh $gutter;
        padding-top: 10vh;
    }

    h2 {
        font-size: 16rem;
        text-align: center;
        font-weight: 600;
    }

    h3 {
        font-size: 4.8rem;
    }

    h4 {
        font-size: 2.5rem;
    }

    p {
        margin-top: $gutter;
        padding-top: $gutter;
        position: relative;
        &::before {
            display: block;
            content: " ";
            width: 10rem;
            height: 2px;
            background: $black;
            position: absolute;
            top: 0;
        }
    }

    .pland-search {
        max-width: 30rem;
    }
}